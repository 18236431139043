<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">{{ $t('front.common.dailyBet') }}</h3>
      <date-filter-Pt :startDate="startDate"
                      :endDate="endDate"
                      @update="updateDate"
                      @search="loadList" />
    </div>
    <article class="content-wrap">
      <div class="strTablescr">
        <div class="strTablePC">
          <table class="statTable">
            <thead>
              <tr>
                <th>{{ $t('front.give.date') }}</th>
                <th>{{ $t('front.common.storeDeposit') }}</th>
                <th>{{ $t('front.common.storeWithdrawal') }}</th>
                <th>{{ $t('front.stributor.m22') }}</th>
                <th>{{ $t('front.stributor.m23') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="dailyBetList && dailyBetList.length > 0">
                <tr v-for="item in dailyBetList" :key="item.days">
                  <td>{{ item.days }}</td>
                  <td class="text-right">{{ thousand(item.dUserPayment || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dUserPayback || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dBetAmt || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dWinAmt || 0) }}</td>
                </tr>
              </template>
              <template v-else>
                <tr><td colspan="5">{{ $t('front.common.notFoundList') }}</td></tr>
              </template>
            </tbody>
            <tfoot v-if="dailyBetSum">
              <tr>
                <td>{{ $t('front.common.totals') }}</td>
                <td class="text-right">{{ thousand(dailyBetSum.dUserPayment || 0) }}</td>
                <td class="text-right">{{ thousand(dailyBetSum.dUserPayback || 0) }}</td>
                <td class="text-right">{{ thousand(dailyBetSum.dBetAmt || 0) }}</td>
                <td class="text-right">{{ thousand(dailyBetSum.dWinAmt || 0) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <ul class="total" v-if="dailyBetSum">
              <li>합계</li>
              <li class="text-right">{{ thousand(dailyBetSum.dUserPayment || 0) }}</li>
              <li class="text-right">{{ thousand(dailyBetSum.dUserPayback || 0) }}</li>
              <li class="text-right">{{ thousand(dailyBetSum.dBetAmt || 0) }}</li>
              <li class="text-right">{{ thousand(dailyBetSum.dWinAmt || 0) }}</li>
            </ul>
            <template v-if="dailyBetList && dailyBetList.length > 0">
              <template v-for="item in dailyBetList" :key="item.days">
                <ul>
                  <li>
                    <em>날짜</em>
                    <div>{{ item.days }}</div>
                  </li>
                  <li>
                    <em>매장 입금</em>
                    <div>{{ thousand(item.dUserPayment || 0) }}</div>
                  </li>
                  <li>
                    <em>매장 출금</em>
                    <div>{{ thousand(item.dUserPayback || 0) }}</div>
                  </li>
                  <li>
                    <em>베팅금</em>
                    <div>{{ thousand(item.dBetAmt || 0) }}</div>
                  </li>
                  <li>
                    <em>당첨금</em>
                    <div>{{ thousand(item.dWinAmt || 0) }}</div>
                  </li>
                </ul>
              </template>
            </template>
            <template v-else>
              <ul>
                <li>내역이 없습니다.</li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import { getGameStaticsDailyBettingV2 } from '@/api/v2/statics'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
export default {
  name: 'DateBet',
  components: { DateFilterPt },
  data () {
    return {
      dailyBetList: null,
      dailyBetSum: null,
      startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
      endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
    }
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  created () {
    this.loadList()
  },
  methods: {
    thousand,
    updateDate (value) {
      this.startDate = value.startDate
      this.endDate = value.endDate
    },
    loadList () {
      this.emitter.emit('Loading', true)
      this.dailyBetList = null
      this.dailyBetSum = null

      const params = {
        memId: this.userData.memId,
        startDate: this.startDate,
        endDate: this.endDate
      }
      getGameStaticsDailyBettingV2(params).then(res => {
        console.log('getGameStaticsDailyBettingV2 : ', params, res.data)
        const result = res.data
        if (result.resultCode === '0') {
          this.dailyBetList = result.data.dailyBetList
          this.dailyBetSum = result.data.dailyBetSum
          this.emitter.emit('Loading', false)
        } else {
          this.emitter.emit('Loading', false)
          alert('다시 시도해주세요. ', result.resultMessage)
        }
      })
    }
  }
}
</script>
<style src="@/styles/pt.css"></style>
<style scoped>
@media (max-width: 1000px) {
  .pagenamPT {flex-direction: column;align-items: flex-start;}
}
</style>
